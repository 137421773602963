import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from '../utils/style-utils';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import LayoutSection from '../components/LayoutSection';
import DS from '../assets/images/desmond-seward.jpg';

const MainContent = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  ${media.desktop`
    margin-top: 5rem;
  `}

  aside {
    flex: 1 0 100%;
    order: -1;
    max-height: 350px;
    overflow: hidden;

    ${media.desktop`
      flex: 1;
      order: 0;
      align-self: center;
      max-height: none;
    `}

    img {
      margin: 4rem 0 0 0;
      width: 100%;
      ${media.desktop`
          min-height: 500px;
          width: auto;
          margin: 0;
        `}
    }
  }

  article {
    flex: 1 0 100%;
    ${media.desktop`
      flex: 0 0 65%;
    `}

    h3 {
      margin: 0;
    }
  }
`;

export default function HomePage() {
  return (
    <>
      <SEO title={`Offical website `} />
      <Layout>
        <LayoutSection isWhite>
          <MainContent>
            <article>
              <h3>About the Author</h3>
              <p>
                Desmond Seward (22 May 1935 - 3 April 2022) was one of Britain’s
                best known popular historians. He wrote professionally for over
                fifty years and was the author of over thirty books of history
                and travel that range from a study of the Hundred Years’ War to
                a comparative biography of Napoleon and Hitler. Many have been
                translated, into twelves languages, including seven into
                Mandarin.
              </p>

              <p>
                His latest is{' '}
                <Link
                  className="link"
                  to="book/the-greatest-viking-the-life-of-olav-haraldsson"
                >
                  The Greatest Viking: The Life of Olav Haraldsson
                </Link>
              </p>

              <p>
                He was born in 1935 in Paris where his father was an
                industrialist – managing director of Timken-Française S.A. –
                into an Anglo-Irish family who had been wine merchants at
                Bordeaux since the 1870s, a background that instilled a lasting
                love of France and the French. (He was a founder of the
                Association de Lassaye, dedicated to preserving a fifteenth
                century château at Lassaye-les-Châteaux in Mayenne owned by
                French cousins.) He was educated at Ampleforth and St
                Catharine’s College, Cambridge – where he was an Exhibitioner in
                History. While believing there is no substitute for a first hand
                study of primary sources, he was also convinced that readability
                and accessibility are an essential part of the historical
                process.
              </p>

              <p>
                In 1991 (in a book of essays on Brooks’s, co-edited with Philip
                Ziegler), he unveiled the true identity of the model for the
                great anti-hero Widmerpool in Anthony Powell’s A Dance to The
                Music of Time.
              </p>
            </article>
            <aside>
              <img src={DS} alt="Desmond Seward" />
            </aside>
          </MainContent>
        </LayoutSection>
      </Layout>
    </>
  );
}
